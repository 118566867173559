const canUseDOM = !!(typeof window !== 'undefined');

/**
 * @description: this function run the scheduler task
 * @param {function} callback: callback to run
 * @param {string} priority: priority to run. options ("user-blocking", "user-visible", "background".).
 */
const runSchedulerTask = (callback, priority) => {
  // Check that feature is supported
  if (canUseDOM && typeof window.scheduler !== 'undefined') {
    window.scheduler
      .postTask(callback, {
        priority,
      })
      .then(console.info) // Log resolved value
      .catch(console.error); // Log error or abort;
  } else {
    setTimeout(() => {
      callback();
    }, 0);
  }
};

export default runSchedulerTask;
