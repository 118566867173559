/* eslint-disable security/detect-object-injection */
import React from 'react';
import isEmpty from 'lodash/isEmpty';
import { MoneyAmount } from '@andes/money-amount';

import StyledLabel from '../components/commons/styled-label';
import IconFull from '../components/commons/icons/full';
import HandShake from '../components/commons/icons/hand-shake';
import IconFactoryModule from '../components/commons/icons/factory-builder';
import { TYPE_PRICE, TYPE_ICON, INTERPOLATION_REGEXP } from '../../utils/constants';

const IconFactory = IconFactoryModule([
  IconFull,
  HandShake,
]);

/**
 * @param {string} text
 * @param {object} values
 */
const interpolator = (text, values, className = null, accessibilityText = null) => {
  if (isEmpty(values)) {
    return text;
  }
  const result = text.split(INTERPOLATION_REGEXP);
  for (let index = 0; index < result.length; index += 1) {
    const key = result[index];
    if (Object.prototype.hasOwnProperty.call(values, key)) {
      if (accessibilityText) values[key].accessibilityText = accessibilityText;
      const replacement = values[key];
      switch (replacement.type) {
        case TYPE_PRICE: {
          const { symbol, currencyId, fraction, cents } = replacement;
          result[index] = (
            <MoneyAmount
              value={{
                fraction,
                cents,
              }}
              symbol={symbol}
              currencyId={currencyId}
              centsType="superscript"
              size={16}
              compact
              key={index}
            />
          );
          break;
        }
        case TYPE_ICON:
        case TYPE_ICON.toUpperCase():
          result[index] = IconFactory(replacement);
          break;
        default:
          result[index] = <StyledLabel key={replacement.text} className={className} as="span" {...replacement} />;
      }
    }
  }
  return result;
};

export default interpolator;
